import { elb } from '@elbwalker/walker.js';
import { Anchor, Body, Headline } from '@sumup/circuit-ui';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { LoginForm } from '../components/LoginPage/LoginForm';
import Auth, { Box } from '../layouts/Auth';
import { config } from '../lib/config/client';
import { handleServerError } from '../lib/errors';
import { appendSafeQueryString, optionalQueryParam } from '../lib/query';
import type { ServerError } from '../services/auth';
import { loadTranslations, safeLocale } from '../services/i18n';
import {
  type AuthError,
  errMissingChallenge,
  mapAuthError,
} from '../services/login';
import { getAppProps } from '../services/mobile-apps';
import type { I18nPageProps } from '../types/i18n';
import { isString, isUndefined } from '../utils/guards';

import styles from './login.module.css';

import Code from 'components/LoginPage/QRCode/Code';
import { useQRCodeChallenge } from 'hooks/useQRCodeChallenge';
import type { AppProps } from 'types/app-props';

type LoginPageProps = {
  challenge: string;
  loginFormURL: string;
  submitError?: AuthError | ServerError;
  loginHint?: string;
  webauthnAssertion?: string;
  webauthnToken?: string;
  renderClosePageButton?: boolean;
  authenticationChallengeID?: string;
  forceLogin: boolean;
} & I18nPageProps &
  AppProps;

const Login: NextPage<LoginPageProps> = ({
  challenge,
  loginFormURL,
  submitError,
  loginHint,
  iframeUri,
  iframeTitle,
  renderAppUI,
  kioskMode = false,
  webauthnAssertion,
  webauthnToken,
  authenticationChallengeID,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const { challengeURL, isSubmitting } = useQRCodeChallenge(
    challenge,
    authenticationChallengeID,
  );

  const passwordResetURL = appendSafeQueryString(
    'password-reset/new',
    router.query,
  );

  const renderExtraColumn = () => {
    if (!challengeURL || !authenticationChallengeID) {
      return null;
    }

    return <Code challengeURL={challengeURL} />;
  };

  return (
    <Fragment>
      <Head>
        <title key="title">
          {intl.formatMessage({
            defaultMessage: 'Login',
            description: 'login page title',
          })}{' '}
          &mdash; SumUp
        </title>
      </Head>
      <Auth
        kioskMode={kioskMode}
        renderAppUI={renderAppUI}
        iframeUri={iframeUri}
        iframeTitle={iframeTitle}
        renderExtraColumn={challengeURL ? renderExtraColumn() : null}
      >
        <Box as="main" className={styles.box}>
          <Headline size="one" as="h1" className={styles.headlineMargin}>
            {intl.formatMessage({
              defaultMessage: 'Login',
              description: 'login form heading',
            })}
          </Headline>
          {!renderAppUI && (
            <Body className={styles.bodyMargin}>
              {intl.formatMessage({
                defaultMessage: 'New to SumUp?',
                description: 'Text for Sign-Up call to action.',
              })}{' '}
              <Anchor
                variant="highlight"
                href={appendSafeQueryString('create', router.query)}
                onClick={() => {
                  elb('button clicked', {
                    business_flow: 'login',
                    button_description: 'register',
                  });
                }}
              >
                {intl.formatMessage({
                  defaultMessage: 'Register',
                  description: 'Text for Sign-Up link.',
                })}
              </Anchor>
            </Body>
          )}
          <LoginForm
            submitURL={loginFormURL}
            formError={submitError}
            challenge={challenge}
            passwordResetURL={passwordResetURL}
            initialEmail={loginHint}
            webauthnAssertion={webauthnAssertion}
            webauthnToken={webauthnToken}
            qrCodeSubmissionLoading={isSubmitting}
          />
        </Box>
      </Auth>
    </Fragment>
  );
};

type LoginQueryParams = {
  login_challenge: string;
  error?: string;
  error_description?: string;
  in_app?: string;
  iframe_uri?: string;
  iframe_title?: string;
  authentication_challenge_id?: string;
};

const getServerSidePageProps: GetServerSideProps<
  LoginPageProps,
  LoginQueryParams
> = async ({ req, query, locale: nextLocale, defaultLocale }) => {
  const locale = safeLocale(nextLocale || defaultLocale);
  const loginFormURL = appendSafeQueryString(
    `${config.urls.apiBasePath}/login`,
    query,
  );

  if (!isString(query.login_challenge) || !query.login_challenge) {
    if (req.headers.referer) {
      return {
        redirect: {
          destination: req.headers.referer,
          permanent: false,
        },
      };
    }

    throw errMissingChallenge;
  }

  const messages = await loadTranslations(locale);

  const errorParam = optionalQueryParam(query.error);
  const submitError = isUndefined(errorParam)
    ? undefined
    : mapAuthError(errorParam);

  const loginHint = optionalQueryParam(query.login_hint);
  const webauthnAssertion = optionalQueryParam(query.webauthn_assertion);
  const webauthnToken = optionalQueryParam(query.webauthn_token);
  const authenticationChallengeID = optionalQueryParam(
    query.authentication_challenge_id,
  );
  const forceLogin =
    typeof optionalQueryParam(query.force_login) !== 'undefined';

  const props: LoginPageProps = {
    ...getAppProps(query),
    challenge: query.login_challenge,
    loginFormURL,
    messages,
    // android check is a temporary solution, see PR for details
    forceLogin,
  };

  if (!isUndefined(loginHint)) {
    props.loginHint = loginHint;
  }

  if (!isUndefined(submitError)) {
    props.submitError = submitError;
  }

  // allow webauthn only in non-kiosk mode
  if (!props.kioskMode) {
    if (!isUndefined(webauthnAssertion)) {
      props.webauthnAssertion = webauthnAssertion;
    }

    if (!isUndefined(webauthnToken)) {
      props.webauthnToken = webauthnToken;
    }
  }

  if (!isUndefined(authenticationChallengeID)) {
    props.authenticationChallengeID = authenticationChallengeID;
  }

  return { props };
};

export const getServerSideProps = handleServerError<
  LoginPageProps,
  LoginQueryParams
>(getServerSidePageProps);

export default Login;
